import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
import ContactUs from "./ContactUs";
import Header from "./Header";
import granthImage from "./images/mahabharat.jpg"; // Assuming this is the correct path to your image

const homeOptionsList = [
  {
    title: "ग्रंथ | Granth",
    image: granthImage, // Replace with your custom image path
    desc: "Sacred texts",
  },
  {
    title: "आरती | Arti",
    image: granthImage, // Replace with your custom image path
    desc: "Devotional prayers",
  },
  {
    title: "मंत्र | Mantra",
    image: granthImage, // Replace with your custom image path
    desc: "Sacred chants",
  },
  {
    title: "भजन | Bhajan",
    image: granthImage, // Replace with your custom image path
    desc: "Devotional songs",
  },
  {
    title: "प्रेरणा | Motivation",
    image: granthImage, // Replace with your custom image path
    desc: "Inspirational videos",
  },
  {
    title: "फीडबैक | Feedback",
    image: granthImage, // Replace with your custom image path
    desc: "Share suggestions",
  },
];

function App() {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleDonationClick = () => {
    alert(
      "We are still working on the donation part. Once our payment gateway is setuped then you can donate and support us!"
    );
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <Router>
      <div>
        <Header onDonateClick={handleDonationClick} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>

        {/* Donation Modal */}
        {isModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <p>We are still working on the donation part.</p>
            </div>
          </div>
        )}
      </div>
    </Router>
  );
}

const Home = () => {
  const handleCardClick = (title) => {
    // Replace this alert with your notification logic
    alert(`You can read and listen to audio through our app only: ${title}`);
  };

  return (
    <div className="home-container">
      <h2>What We Provide</h2>
      <div className="home-options-grid">
        {homeOptionsList.map((option, index) => (
          <div
            key={index}
            className="home-option-card"
            onClick={() => handleCardClick(option.title)} // Add onClick event
            style={{ cursor: "pointer" }} // Change cursor to pointer for better UX
          >
            <img
              src={option.image}
              alt={option.title}
              className="home-option-image"
            />
            <h3>{option.title}</h3>
            <p>{option.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
