import React from "react";
import { Link } from "react-router-dom";

const Header = ({ onDonateClick }) => {
  return (
    <header>
      <h1>Gurukul Dwar</h1>
      <nav>
        <Link to="/">Home</Link> |
        <Link to="/terms-and-conditions">Terms and Conditions</Link> |
        <Link to="/contact-us">Contact Us</Link> |
        <button onClick={onDonateClick} className="donate-now-button">
          Donate Now
        </button>
      </nav>
    </header>
  );
};

export default Header;
