import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <h2>Terms and Conditions</h2>
      <p>
        A “Terms & Conditions” page outlines the legal agreements between the
        company & its customers, including any limitations of liability, usage
        restrictions & dispute resolution procedures.
      </p>
      <ul>
        <li>
          <strong>Service/Product Details & User Responsibilities</strong>:
          Gurukul Dwar provides access to Hindu religious texts, mantras, and
          motivational videos through our mobile app and website. Users are
          responsible for ensuring appropriate usage of the content.
        </li>
        <li>
          <strong>Payment Terms & Usage Restrictions</strong>: All content on
          the app is provided for educational and spiritual purposes. Users are
          not permitted to republish or redistribute the content without prior
          written consent.
        </li>
        <li>
          <strong>Intellectual Property Ownership</strong>: All content,
          including religious texts and motivational videos, is the intellectual
          property of Gurukul Dwar.
        </li>
        <li>
          <strong>Limitations of Liability</strong>: Gurukul Dwar will not be
          held responsible for any misuse of the content or harm caused by
          misinterpretation. We provide the content "as-is" without any
          guarantees.
        </li>
        <li>
          <strong>Dispute Resolution</strong>: Any disputes arising from the use
          of Gurukul Dwar services will be handled in the jurisdiction of
          Lucknow, Uttar Pradesh.
        </li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
