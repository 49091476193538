import React from "react";

const ContactUs = () => {
  return (
    <div>
      <h2>Contact Us</h2>
      <p>you cam connect Us with</p>
      <ul>
        <li>
          Registered Name of your Business: <strong>Gurukul Dwar</strong>
        </li>
        <li>
          Support Contact: Email -{" "}
          <a href="mailto:support@gurukuldwar.com">support@gurukuldwar.com</a>
        </li>
        <li>
          Support Contact: Mobile - <strong>+91 9876543210</strong>
        </li>
        <li>
          Operating Address: Eldeco Udyan, Raiberali Road, Lucknow, Uttar
          Pradesh - 226025
        </li>
      </ul>
    </div>
  );
};

export default ContactUs;
